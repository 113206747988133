import { rem } from 'polished'
import { GetStaticProps } from 'next'
import { Box } from '@chakra-ui/react'

import SectionFeaturedProjects from 'components/sections/SectionFeaturedProjects'
import ContainerSlanted from 'components/containers/ContainerSlanted'
import SectionCopyCta from 'components/sections/SectionCopyCta'
import HeroHome from 'components/heroes/HeroHome'
import Layout from 'components/common/Layout'

import { ContentfulGlobalSettings, ContentfulHomePageGraph } from 'contentful/types'
import { FOOTER_SLANT_SM, FOOTER_SLANT_XL } from 'components/Footer'
import { getHomePageProps } from 'contentful/api'

function Home({
  pageData,
  settings,
}: {
  pageData: ContentfulHomePageGraph
  settings: ContentfulGlobalSettings
}): JSX.Element {
  const {
    seoMetadata,
    whatWeDoBlockA,
    whatWeDoBlockB,
    heroSubTitle,
    heroTitle: { json: heroTitle },
    whatWeDoButtonText,
    whatWeDoTitle,
    whatWeDoTargetUrl,
    heroTargetUrl,
    featuredProjectsButtonText,
    featuredProjectsTargetUrl,
    featuredProjectsTitle,
    featuredProjectsCollection: { items: featuredProjects },
  } = pageData

  return (
    <Layout metadata={seoMetadata} settings={settings}>
      <Box as="article" w="100%">
        <HeroHome zIndex="heroHome" heading={heroTitle} subheading={heroSubTitle} href={heroTargetUrl} />

        <ContainerSlanted
          slant={{
            base: {
              tr: 30,
              br: FOOTER_SLANT_SM,
            },
            xl: {
              br: FOOTER_SLANT_XL,
              tr: 80,
            },
          }}
          bgColor="blue_13"
        >
          <SectionCopyCta
            eyebrow={whatWeDoTitle}
            heading={whatWeDoBlockA}
            body={whatWeDoBlockB}
            button={{ cta: whatWeDoButtonText, href: whatWeDoTargetUrl }}
          />

          <SectionFeaturedProjects
            mt={{ xl: rem(76) }}
            eyebrow={featuredProjectsTitle}
            items={featuredProjects}
            cta={featuredProjectsButtonText}
            href={featuredProjectsTargetUrl}
            button={{ cta: featuredProjectsButtonText, href: featuredProjectsTargetUrl }}
          />
        </ContainerSlanted>
      </Box>
    </Layout>
  )
}

Home.propTypes = {}

export default Home

export const getStaticProps: GetStaticProps = getHomePageProps()
