import { rem } from 'polished'
import dynamic from 'next/dynamic'
import { chakra } from '@chakra-ui/react'

import FeaturedProjects from 'components/FeaturedProjects'
import ContainerGrid from 'components/containers/ContainerGrid'
import ButtonArrow from 'components/buttons/ButtonArrow'
import { GlowStickType } from 'components/GlowStick'
import Eyebrow from 'components/Eyebrow'

import { pct, offset } from 'utils/designHelper'
import { GRID_WIDTH_XL } from 'constants/design'
import colors from 'constants/colors'
import { ContentfulGraphRelatedProject } from '../../contentful/types'

interface SectionFeaturedProjectsProps {
  eyebrow: string
  items: ContentfulGraphRelatedProject[]
  cta: string
  href: string
  className?: string
}

const ArtworkOrbits = dynamic(() => import('components/artwork/ArtworkOrbits'))
const GlowStick = dynamic<GlowStickType>(() => import('components/GlowStick'))

const SectionFeaturedProjects = ({ className, eyebrow, items, cta, href }: SectionFeaturedProjectsProps) => {
  return (
    <ContainerGrid tag="section" className={className} pos="relative" pb={{ base: rem(54) }}>
      <Eyebrow tag="h2" text={eyebrow} ml={{ xl: offset('xl', 3, 12) }} />

      <FeaturedProjects mt={{ base: rem(20), xl: rem(36) }} items={items} />

      <ButtonArrow mt={{ base: rem(40), xl: rem(35) }} ml={{ xl: offset('xl', 3, 12) }} href={href}>
        {cta}
      </ButtonArrow>

      <ArtworkOrbits
        pos="absolute"
        top={{ xl: `calc(100% - ${rem(400)})` }}
        left={{ xl: pct(450, GRID_WIDTH_XL) }}
        w={{ xl: pct(1000, GRID_WIDTH_XL) }}
        h="auto"
      />

      <GlowStick
        pos="absolute"
        right={{ base: rem(-20), xl: rem(92) }}
        bottom={{ base: rem(146), xl: rem(128) }}
        w={{ base: rem(164), xl: rem(350) }}
        glow={{ color: colors.white }}
        rotation={{ base: -30, xl: -44 }}
      />
    </ContainerGrid>
  )
}

export default chakra(SectionFeaturedProjects)
