import { Box, Text } from '@chakra-ui/react'
import { Document } from '@contentful/rich-text-types'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

export const CONTENTFUL_API_KEY: string = process.env.NEXT_PUBLIC_CONTENTFUL_API_KEY
export const CONTENTFUL_SPACE_ID: string = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID
export const CONTENTFUL_ENVIRONMENT_ID: string = process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT_ID

export function renderContentfulHeading(heading: Document) {
  return documentToHtmlString(heading, {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, next) => next(node.content),
      [BLOCKS.HEADING_1]: (node, next) => next(node.content),
      [BLOCKS.HEADING_2]: (node, next) => next(node.content),
      [BLOCKS.HEADING_3]: (node, next) => next(node.content),
      [BLOCKS.HEADING_4]: (node, next) => next(node.content),
      [BLOCKS.HEADING_5]: (node, next) => next(node.content),
      [BLOCKS.HEADING_6]: (node, next) => next(node.content),
    },
  })
}

export function renderContentfulBody(body: Document, props = {}) {
  return documentToReactComponents(body, {
    renderMark: {
      [MARKS.ITALIC]: (text) => <Box as="em">{text}</Box>,
      [MARKS.BOLD]: (text) => <Box as="strong">{text}</Box>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Text
          css={`
            & + * {
              margin-top: 1em;
            }
          `}
          {...props}
        >
          {children}
        </Text>
      ),
    },
  })
}
