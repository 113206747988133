import { useEffect, useRef } from 'react'

const useInterval = (callback: (...args: any) => void, delay: number, isEnabled = true) => {
  const savedCallback = useRef<(...args: any) => void>(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const handler = (...args) => savedCallback.current(...args)

    if (delay !== null && isEnabled) {
      const id: NodeJS.Timer = setInterval(handler, delay)
      return () => clearInterval(id)
    }
  }, [delay, isEnabled])
}

export default useInterval
