import { rem } from 'polished'
import { useRef } from 'react'
import dynamic from 'next/dynamic'
import { chakra, Flex } from '@chakra-ui/react'

import { MotionIconType } from 'components/common/Icons'
import Button from 'components/buttons/Button'

import useIntersectionObserver from 'hooks/useIntersectionObserver'

import colors from 'constants/colors'

interface ButtonArrowProps {
  children: React.ReactNode
  href?: string
  className?: string
  circleColor?: string
  rotation?: number
  onClick?: () => void
}

const IconArrow = dynamic<MotionIconType>(() => import('components/common/Icons').then((mod) => mod.IconArrow))
const AnimatedCircleAlt = dynamic(() => import('components/AnimatedCircleAlt'))

const ButtonArrow = ({ children, className, href, circleColor, rotation, onClick }: ButtonArrowProps) => {
  const ref = useRef<HTMLButtonElement | HTMLAnchorElement | null>(null)

  const entry = useIntersectionObserver(ref, {})

  return (
    <Button
      className={className}
      display="inline-flex"
      alignItems="center"
      fontSize={{ base: rem(14) }}
      letterSpacing="0.015em"
      lineHeight={{ base: rem(20) }}
      initial="initial"
      animate={entry?.isIntersecting ? 'visible' : 'initial'}
      whileHover="hover"
      href={href}
      onClick={onClick}
      ref={ref}
    >
      {children}

      <Flex
        as="span"
        pos="relative"
        alignItems="center"
        justifyContent="center"
        flexShrink={0}
        w={rem(50)}
        h={rem(50)}
        ml={{ base: rem(15) }}
        transform={rotation && `rotate(${rotation}deg)`}
        pointerEvents="none"
      >
        <AnimatedCircleAlt pos="absolute" top="0" left="0" w="100%" h="100%" stroke={circleColor || colors.blue_1} />

        <IconArrow fontSize={{ base: rem(18) }} lineHeight="0" variants={variantsIcon} />
      </Flex>
    </Button>
  )
}

const variantsIcon = {
  initial: {
    // rotateY: -90,
    x: rem(-15),
    // scale: 0,
  },
  visible: {
    rotateY: 0,
    x: 0,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: [0.25, 0, 0, 1],
    },
  },
  // hover: {
  //   rotateY: [0, 90, 0],
  //   x: [rem(0), rem(18), rem(-18), rem(0)],
  //   scale: [1, 0.4, 0.4, 1],
  //   transition: {
  //     duration: 0.6,
  //     ease: 'easeInOut',
  //   },
  // },
  hover: {
    opacity: [1, 0, 0, 1],
    x: [rem(0), rem(20), rem(-20), rem(0)],
    transition: {
      duration: 0.6,
      ease: 'easeInOut',
    },
  },
}

export default chakra(ButtonArrow)
