import { rem } from 'polished'
import dynamic from 'next/dynamic'
import { chakra, Flex, Box } from '@chakra-ui/react'

import ContainerGrid from 'components/containers/ContainerGrid'
import ButtonArrow from 'components/buttons/ButtonArrow'
import { GlowStickType } from 'components/GlowStick'
import AnimatedText from 'components/AnimatedText'
import Eyebrow from 'components/Eyebrow'

import { grid, offset, pct } from 'utils/designHelper'
import { GRID_WIDTH_SM } from 'constants/design'
import colors from 'constants/colors'

interface SectionCopyCtaProps {
  eyebrow: string
  heading: string
  body: string
  button: {
    cta: string
    href: string
  }
  className?: string
}

const ArtworkOrbits = dynamic(() => import('components/artwork/ArtworkOrbits'))
const GlowStick = dynamic<GlowStickType>(() => import('components/GlowStick'))

const SectionCopyCta = ({ className, eyebrow, heading, body, button }: SectionCopyCtaProps) => {
  return (
    <ContainerGrid tag="section" className={className}>
      <Flex pos="relative" alignItems="center" w={{ xl: grid('xl', 6, 12) }} ml={{ xl: offset('xl', 1, 12) }}>
        <ArtworkOrbits
          pos="absolute"
          right={{ base: pct(-52, GRID_WIDTH_SM), xl: pct(-62, 590) }}
          w={{ base: pct(1026, GRID_WIDTH_SM), xl: pct(1550, 590) }}
          h="auto"
          mt={{ base: rem(200), xl: rem(272) }}
        />

        <Box pos="relative" mt={{ base: rem(54), xl: rem(65) }}>
          <Eyebrow text={eyebrow} />

          <AnimatedText
            tag="h2"
            mt={{ base: rem(40), xl: rem(60) }}
            pr={{ base: rem(12) }}
            fontFamily="headingAlt"
            fontSize={{ base: rem(40), xl: rem(64) }}
            fontWeight={200}
            letterSpacing="-1px"
            lineHeight={{ base: rem(50), xl: rem(73) }}
          >
            {heading}
          </AnimatedText>

          <GlowStick
            display={{ base: 'none', xl: 'block' }}
            pos="absolute"
            bottom={{ xl: rem(-290) }}
            left={{ xl: rem(-180) }}
            w={{ xl: rem(400) }}
            glow={{ color: colors.blue_1 }}
            rotation={{ base: 48 }}
          />
        </Box>
      </Flex>

      <Box
        pos="relative"
        w={{ xl: grid('xl', 4, 12) }}
        mt={{ base: rem(34), xl: rem(165) }}
        ml={{ xl: offset('xl', 7, 12) }}
        pb={{ base: rem(138) }}
      >
        <AnimatedText fontSize={{ base: rem(16), xl: rem(18) }} lineHeight={rem(32)} opacity={0.8}>
          {body}
        </AnimatedText>

        <ButtonArrow mt={{ base: rem(28), xl: rem(50) }} ml={{ base: rem(2) }} href={button.href}>
          {button.cta}
        </ButtonArrow>

        <GlowStick
          pos="absolute"
          top={{ xl: rem(-240) }}
          right={{ base: rem(-6), xl: rem(-46) }}
          bottom={{ base: rem(112), xl: 'auto' }}
          w={{ base: rem(164), xl: rem(228) }}
          glow={{ color: colors.red_1 }}
          rotation={{ base: -44 }}
          parallax={[-30, 30]}
        />
      </Box>
    </ContainerGrid>
  )
}

export default chakra(SectionCopyCta)
