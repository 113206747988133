import { rem } from 'polished'
import { useEffect } from 'react'
import dynamic from 'next/dynamic'
import { AnimatePresence } from 'framer-motion'
import { chakra, Box, List, ListItem } from '@chakra-ui/react'

import FeaturedProjectThumbnail from 'components/featured-project/FeaturedProjectThumbnail'
import FeaturedProjectGradient from 'components/featured-project/FeaturedProjectGradient'
import { MotionIconType } from 'components/common/Icons'
import Button from 'components/buttons/Button'
import { MotionBox } from 'components/Motion'

import useIntersectionObserver from 'hooks/useIntersectionObserver'
import useElementRect from 'hooks/useElementRect'
import useMediaQuery from 'hooks/useMediaQuery'

import { grid, GRID_GAP_XL } from 'utils/designHelper'
import breakpoints from 'constants/breakpoints'
import { ContentfulGraphAsset } from '../../contentful/types'

interface FeaturedProjectProps {
  uid: string
  href: FeaturedProjectInterface['href']
  client: FeaturedProjectInterface['client']
  filters: FeaturedProjectInterface['filters']
  title: FeaturedProjectInterface['title']
  thumbnail: FeaturedProjectInterface['thumbnail']
  gradient: FeaturedProjectInterface['gradient']
  onChangeScrollRange: (params: ScrollRangeParams) => void
  onChangeActiveProject: (uid: FeaturedProjectInterface['uid']) => void
  isActive: boolean
  className?: string
}

export interface ScrollRangeParams {
  uid: FeaturedProjectInterface['uid']
  range: [number, number]
}

export interface FeaturedProjectInterface {
  uid: string
  href: string
  client: string
  filters: string[]
  title: string
  shortTitle: string
  thumbnail: ContentfulGraphAsset | undefined
  gradient: string[]
}

const IconArrow = dynamic<MotionIconType>(() => import('components/common/Icons').then((mod) => mod.IconArrow))

const FeaturedProject = ({
  className,
  uid,
  href,
  client,
  title,
  filters,
  thumbnail,
  gradient,
  isActive,
  onChangeScrollRange,
  onChangeActiveProject,
}: FeaturedProjectProps) => {
  const isNarrowScreen = useMediaQuery(breakpoints.lg)

  const [rect, ref] = useElementRect()
  const entry = useIntersectionObserver(ref, {})

  function handleMouseEnter() {
    onChangeActiveProject(uid)
  }

  function handleMouseLeave() {
    onChangeActiveProject(null)
  }
  useEffect(() => {
    if (rect && isNarrowScreen) {
      const windowOffset = window.innerHeight / 2

      const top = Math.round(rect.y - windowOffset)
      const bottom = Math.round(rect.y + rect.height - windowOffset)

      if (typeof onChangeScrollRange === 'function') {
        onChangeScrollRange({
          range: [top, bottom],
          uid,
        })
      }
    }
  }, [uid, rect?.y, rect?.height, isNarrowScreen])

  return (
    <MotionBox
      className={className}
      pos="relative"
      display="flex"
      alignItems="center"
      animate={isActive ? 'active' : 'initial'}
      py={{ base: rem(21), xl: rem(35) }}
      ref={ref}
    >
      <AnimatePresence>
        {isActive && (
          <FeaturedProjectGradient pos="absolute" left={{ base: rem(-50), xl: rem(100) }} values={gradient} />
        )}
      </AnimatePresence>

      {thumbnail && (
        <FeaturedProjectThumbnail
          isActive={entry?.isIntersecting && isActive}
          src={thumbnail.url}
          alt={thumbnail.title}
          contentType={thumbnail.contentType}
        />
      )}

      <Button
        pos="relative"
        display={{ xl: 'flex' }}
        alignItems={{ xl: 'center' }}
        w="100%"
        variants={variantsButton}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        href={href}
      >
        <Box
          order={{ xl: '1' }}
          ml={{ xl: rem(GRID_GAP_XL) }}
          fontFamily="headingAlt"
          fontSize={{ base: rem(74), xl: rem(144) }}
          letterSpacing={{ base: '-0.02em', xl: rem(-5) }}
          lineHeight={{ base: rem(74), xl: rem(140) }}
        >
          {title}
        </Box>

        <Box
          order={{ xl: '0' }}
          display={{ xl: 'flex' }}
          alignItems={{ xl: 'center' }}
          flexShrink={0}
          w={{ xl: grid('xl', 3, 12) }}
          mt={{ base: rem(9) }}
          fontSize={{ base: rem(11), xl: rem(12) }}
          letterSpacing={{ base: '0.15em', xl: rem(2) }}
          lineHeight={{ base: rem(18), xl: rem(20) }}
          textTransform="uppercase"
        >
          <Box flexShrink={0} w={{ xl: grid('xl', 2, 3) }}>
            <Box fontWeight={800}>{client}</Box>

            {filters.length > 0 && (
              <List display="flex" flexWrap="wrap">
                {filters.map((tag, index) => (
                  <ListItem key={tag}>
                    {tag}

                    {filters.length > 1 && index !== filters.length - 1 && (
                      <Box as="span" mx={{ base: rem(4) }}>
                        /
                      </Box>
                    )}
                  </ListItem>
                ))}
              </List>
            )}
          </Box>

          <MotionBox
            variants={variantsIcon}
            pos={{ base: 'absolute', xl: 'unset' }} // iOS visual bug fix
            visibility={{ base: 'hidden', xl: 'unset' }} // iOS visual bug fix
          >
            <IconArrow w={{ xl: rem(56) }} h={{ xl: 'auto' }} />
          </MotionBox>
        </Box>
      </Button>
    </MotionBox>
  )
}

const variantsButton = {
  initial: {
    opacity: 0.3,
    transition: {
      ease: 'linear',
    },
  },
  active: {
    opacity: 1,
    transition: {
      ease: 'linear',
    },
  },
}

const variantsIcon = {
  initial: {
    opacity: 0,
    transition: {
      ease: 'linear',
    },
  },
  active: {
    opacity: 1,
    transition: {
      ease: 'linear',
    },
  },
}

export default chakra(FeaturedProject)
