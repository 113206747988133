import { useRef } from 'react'
import { As, chakra } from '@chakra-ui/react'

import { MotionText } from 'components/Motion'

import useIntersectionObserver from 'hooks/useIntersectionObserver'

interface AnimatedTextProps {
  children: string | object
  className?: string
  tag?: As
}

const OFFSET_Y = 40

const AnimatedText = ({ children, className, tag }: AnimatedTextProps) => {
  const ref = useRef()

  const entry = useIntersectionObserver(ref, { rootMargin: `${OFFSET_Y}px 0px 0px 0px` })

  return (
    <MotionText
      className={className}
      as={tag}
      variants={variants}
      initial="initial"
      animate={entry?.isIntersecting ? 'animate' : 'initial'}
      ref={ref}
    >
      {children}
    </MotionText>
  )
}

const variants = {
  initial: {
    opacity: 0,
    y: OFFSET_Y,
    transition: {
      duration: 0,
    },
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      opacity: {
        ease: 'linear',
      },
      y: {
        ease: [0.25, 0, 0, 1],
        duration: 1,
      },
    },
  },
}

export default chakra(AnimatedText)
