import { chakra } from '@chakra-ui/react'
import { ImageProps as NextImageProps } from 'next/image'

import ImageParallax from 'components/images/ImageParallax'
import Image from 'components/images/Image'
import Video from 'components/Video'

import useMediaQuery from 'hooks/useMediaQuery'

import breakpoints from 'constants/breakpoints'

interface MediaProps {
  src: MediaInterface['src']
  alt: MediaInterface['alt']
  contentType: MediaInterface['contentType']
  nextConfig: MediaInterface['nextConfig']
  className?: string
  useParallax?: boolean
}

export interface MediaInterface {
  src: string
  alt: string
  contentType: string
  nextConfig?: {
    width?: NextImageProps['width']
    height?: NextImageProps['height']
    layout?: NextImageProps['layout']
    objectFit?: NextImageProps['objectFit']
    priority?: NextImageProps['priority']
  }
}

const Media = ({ className, src, alt, contentType, useParallax, nextConfig, ...props }: MediaProps) => {
  if (contentType === 'video/mp4') {
    return <Video className={className} src={src} {...props} />
  }

  const isNarrowScreen = useMediaQuery(breakpoints.lg)

  return useParallax ? (
    <ImageParallax
      className={className}
      src={src}
      alt={alt}
      parallax={isNarrowScreen ? 20 : 80}
      nextConfig={nextConfig}
      {...props}
    />
  ) : (
    <Image
      className={className}
      src={src}
      alt={alt}
      layout={nextConfig?.layout}
      objectFit={nextConfig?.objectFit}
      priority={nextConfig?.priority}
      {...props}
    />
  )
}

export default chakra(Media)
