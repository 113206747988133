import { useEffect } from 'react'
import { useMotionValue, animate, AnimationOptions } from 'framer-motion'

import useInterval from 'hooks/useInterval'

const ANIMATION_IN_IDLE = { type: 'tween', ease: 'easeIn', duration: 0.4 } as AnimationOptions<number>
const ANIMATION_OUT_IDLE = { type: 'tween', ease: 'easeOut', duration: 0.4 } as AnimationOptions<number>
const ANIMATION_IN_HOVER = { type: 'tween', ease: [0.5, 0, 0.15, 1], duration: 0.6 } as AnimationOptions<number>

const INTERVAL = 4000
const CLIP_SLANT = 10

interface useTextShineClipPathParams {
  isEnabled: boolean
  isAnimating: boolean
  isIdling: boolean
  onAnimateIn: (isDone: boolean) => void
  isIntervalActive?: boolean
}

function useTextShineClipPath({
  isEnabled,
  isAnimating,
  isIdling,
  isIntervalActive = true,
  onAnimateIn,
}: useTextShineClipPathParams) {
  const topLeft = useMotionValue(0)
  const topRight = useMotionValue(0)
  const bottomRight = useMotionValue(0)
  const bottomLeft = useMotionValue(0)

  function handleInterval() {
    onAnimateIn(true)
  }

  useInterval(handleInterval, isEnabled && isIdling ? INTERVAL : null, isIntervalActive)

  useEffect(() => {
    if (isAnimating === null) {
      return
    }

    if (isAnimating) {
      const options = isIdling ? ANIMATION_IN_IDLE : ANIMATION_IN_HOVER

      const controlsTopLeft = animate(topLeft, -CLIP_SLANT, options)
      const controlsTopRight = animate(topRight, 100 + CLIP_SLANT, options)
      const controlsBottomLeft = animate(bottomLeft, -CLIP_SLANT * 2, options)
      const controlsBottomRight = animate(bottomRight, 100, {
        ...options,
        onComplete: () => {
          if (isIdling) {
            onAnimateIn(false)
          }
        },
      })

      return () => {
        controlsTopLeft.stop()
        controlsTopRight.stop()
        controlsBottomLeft.stop()
        controlsBottomRight.stop()
      }
    }

    const controlsTopRight = animate(topRight, 100 + CLIP_SLANT * 2, ANIMATION_OUT_IDLE)
    const controlsBottomRight = animate(bottomRight, 100 + CLIP_SLANT, ANIMATION_OUT_IDLE)
    const controlsTopLeft = animate(topLeft, 100 + CLIP_SLANT, ANIMATION_OUT_IDLE)
    const controlsBottomLeft = animate(bottomLeft, 100, {
      ...ANIMATION_OUT_IDLE,
      onComplete: () => {
        topLeft.set(0)
        topRight.set(0)
        bottomRight.set(0)
        bottomLeft.set(0)
      },
    })

    return () => {
      controlsTopLeft.stop()
      controlsTopRight.stop()
      controlsBottomRight.stop()
      controlsBottomLeft.stop()
    }
  }, [isAnimating, isIdling])

  return [topLeft, topRight, bottomRight, bottomLeft]
}

export default useTextShineClipPath
