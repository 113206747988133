import { useState, useEffect } from 'react'

import useIntersectionObserver from 'hooks/useIntersectionObserver'
import useMousePosition from 'hooks/useMousePosition'
import useElementRect from 'hooks/useElementRect'

interface DistanceInterface {
  value: number
  x: number
  y: number
}

const useMouseDistance = (refParam: React.MutableRefObject<HTMLElement>, isEnabled?: boolean) => {
  const [distance, setDistance] = useState<DistanceInterface | null>(null)

  const [rect] = useElementRect(refParam)
  const entry = useIntersectionObserver(refParam, {})
  const mouse = useMousePosition({ isEnabled: isEnabled !== undefined ? isEnabled : entry?.isIntersecting })

  useEffect(() => {
    if (!rect || !mouse) {
      setDistance({
        value: 0,
        x: 0,
        y: 0,
      })

      return
    }

    const elementCenterX = rect.x + rect.width / 2
    const elementCenterY = rect.y + rect.height / 2

    const dx = mouse.x - elementCenterX
    const dy = mouse.y - elementCenterY
    const distance = Math.round(Math.sqrt(dx * dx + dy * dy))

    setDistance({
      value: distance,
      x: dx,
      y: dy,
    })
  }, [rect, mouse])

  return distance
}

export default useMouseDistance
