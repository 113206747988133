import { useRef, useState } from 'react'
import { chakra, List, ListItem } from '@chakra-ui/react'

import FeaturedProject, {
  FeaturedProjectInterface,
  ScrollRangeParams,
} from 'components/featured-project/FeaturedProject'

import useIntersectionObserver from 'hooks/useIntersectionObserver'
import useScrollTicker from 'hooks/useScrollTicker'
import { ContentfulGraphRelatedProject } from '../contentful/types'

interface FeaturedProjectsProps {
  items: ContentfulGraphRelatedProject[]
  className?: string
}

export interface FeaturedProjectsInterface {
  items: FeaturedProjectInterface[]
}

const FeaturedProjects = ({ className, items }: FeaturedProjectsProps) => {
  const ref = useRef<HTMLUListElement | null>(null)

  const [projectScrollRanges, setProjectScrollRanges] = useState<ScrollRangeParams[]>([])
  const [activeProject, setActiveProject] = useState(null)

  const entry = useIntersectionObserver(ref, {})

  function handleChangeActiveProject(uid: FeaturedProjectInterface['uid']) {
    setActiveProject(uid)
  }

  function handleChangeScrollRangeProject(projectScrollRange: ScrollRangeParams) {
    setProjectScrollRanges((prevState) => {
      const isEntryFound = (item: ScrollRangeParams) => item?.uid === projectScrollRange.uid

      const entryIndex = prevState.findIndex(isEntryFound)
      const entryExists = entryIndex > -1
      const isNewEntry = !entryExists

      const copiedScrollRanges = [...prevState]

      if (isNewEntry) {
        copiedScrollRanges.push(projectScrollRange)
      } else if (entryExists) {
        copiedScrollRanges[entryIndex] = projectScrollRange
      }

      return copiedScrollRanges
    })
  }

  function handleScrollTick() {
    if (projectScrollRanges.length === 0) {
      return
    }

    let activeUid = null

    for (let i = 0, len = projectScrollRanges.length; i < len; i++) {
      const projectScrollRange = projectScrollRanges[i]
      const isInRange = projectScrollRange.range[0] <= window.scrollY && projectScrollRange.range[1] > window.scrollY

      if (isInRange) {
        activeUid = projectScrollRange.uid

        break
      }
    }

    if (activeUid !== activeProject) {
      setActiveProject(activeUid)
    }
  }

  useScrollTicker(handleScrollTick, entry?.isIntersecting, [projectScrollRanges, activeProject])

  // todo: determine if we actually need shortTitle or if we can use title everywhere (e.g., "The 1776 Pack" v "1776 Pack")
  return (
    items.length > 0 && (
      <List className={className} ref={ref}>
        {items.map((item, i) => {
          const {
            sys,
            slug,
            shortTitle,
            gradient,
            client: { name: client },
            previewThumbnail,
            filtersCollection: { items: filters },
            heroMedia,
          } = item
          const id = sys.id

          return (
            <ListItem pos="relative" zIndex={id === activeProject ? 1 : 0} key={`featured-project-${i}`}>
              <FeaturedProject
                uid={id}
                href={`/work/${slug}`}
                client={client}
                title={shortTitle}
                filters={filters.map((value) => value.type)}
                thumbnail={heroMedia || previewThumbnail}
                gradient={gradient}
                isActive={id === activeProject}
                onChangeScrollRange={handleChangeScrollRangeProject}
                onChangeActiveProject={handleChangeActiveProject}
              />
            </ListItem>
          )
        })}
      </List>
    )
  )
}

export default chakra(FeaturedProjects)
