import { rem } from 'polished'
import { chakra } from '@chakra-ui/react'

import GradientCircle from 'components/GradientCircle'
import { MotionBox } from 'components/Motion'

import colors from 'constants/colors'

interface FeaturedProjectGradientProps {
  values: string[]
  className?: string
}

const FeaturedProjectGradient = ({ className, values }: FeaturedProjectGradientProps) => {
  return (
    <MotionBox
      className={className}
      w={{ base: rem(1546) }}
      h={{ base: rem(904) }}
      pointerEvents="none"
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <GradientCircle
        top={{ base: 0 }}
        left={{ base: rem(53), xl: rem(140) }}
        w={{ base: rem(1070) }}
        h={{ base: rem(889) }}
        gradientColor={values?.[0] || colors.pink_1}
        opacity={0.4}
        isReversed={true}
      />
      <GradientCircle
        bottom={{ base: 0 }}
        left={{ base: rem(48), xl: rem(404) }}
        w={{ base: rem(756) }}
        h={{ base: rem(889) }}
        gradientColor={values?.[1] || colors.purple_2}
        opacity={0.4}
      />
      <GradientCircle
        top={{ base: rem(64) }}
        w={{ base: rem(1546) }}
        h={{ base: rem(904) }}
        gradientColor={values?.[2] || colors.blue_1}
        opacity={0.5}
        isReversed={true}
      />
      <GradientCircle
        top={{ base: rem(130) }}
        left={{ base: rem(130) }}
        w={{ base: rem(1296) }}
        h={{ base: rem(904) }}
        gradientColor={values?.[2] || colors.blue_1}
        opacity={0.9}
      />
    </MotionBox>
  )
}

const variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

export default chakra(FeaturedProjectGradient)
