import { useEffect, useRef } from 'react'
import { chakra } from '@chakra-ui/react'
import { useAnimation } from 'framer-motion'

import { MotionBox } from 'components/Motion'

import useIntersectionObserver from 'hooks/useIntersectionObserver'
// import useMouseTracking from 'hooks/useMouseTracking'

export interface GradientCircleProps {
  gradientColor: string
  className?: number
  restraint?: number
  isReversed?: boolean
}

const AMBIENT_MOTION = 100
// const DEFAULT_RESTRAINT = 300

const GradientCircle = ({
  className,
  gradientColor,
  // restraint = DEFAULT_RESTRAINT,
  isReversed,
}: GradientCircleProps) => {
  const ref = useRef<HTMLElement | null>(null)

  const animationControls = useAnimation()
  const entry = useIntersectionObserver(ref, {})
  // const [springX, springY] = useMouseTracking({ ref, restraint, isEnabled: entry?.isIntersecting })

  useEffect(() => {
    if (entry?.isIntersecting) {
      const a = isReversed ? -AMBIENT_MOTION : AMBIENT_MOTION

      animationControls.start({
        x: [-a, a, -a],
        y: [a, -a, a, -a, a, -a, a],
        transition: {
          duration: 20,
          repeat: Infinity,
        },
      })
    }

    return () => {
      animationControls.stop()
    }
  }, [entry, isReversed, animationControls])

  return (
    <MotionBox
      className={className}
      pos="absolute"
      borderRadius="50%"
      pointerEvents="none"
      bg={`radial-gradient(closest-side, ${gradientColor}, transparent)`}
      animate={animationControls}
      ref={ref}
    />
    // <MotionBox className={className} pos="absolute" animate={animationControls}>
    //   <MotionBox
    //     w="100%"
    //     h="100%"
    //     borderRadius="50%"
    //     pointerEvents="none"
    //     style={{
    //       x: springX,
    //       y: springY,
    //     }}
    //     bg={`radial-gradient(closest-side, ${gradientColor}, transparent)`}
    //     ref={ref}
    //   />
    // </MotionBox>
  )
}

export default chakra(GradientCircle)
