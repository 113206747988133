import { chakra } from '@chakra-ui/react'
import { HTMLMotionProps } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

import { MotionBox } from 'components/Motion'

import useIntersectionObserver from 'hooks/useIntersectionObserver'

type VideoProps = HTMLMotionProps<'video'> & {
  src: string
}

const Video = ({ src, ...props }: VideoProps) => {
  const ref = useRef<HTMLVideoElement>()

  const [isLoaded, setIsLoaded] = useState(false)

  const entry = useIntersectionObserver(ref, { rootMargin: '0% 0% 100% 0%' })

  function handleLoadedData() {
    setIsLoaded(true)
  }

  useEffect(() => {
    if (entry?.isIntersecting && !isLoaded) {
      ref.current.src = ref.current.dataset.src
      ref.current.load()
    }
  }, [entry, ref, isLoaded])

  useEffect(() => {
    if (isLoaded) {
      entry?.isIntersecting ? ref.current.play() : ref.current.pause()
    }
  }, [entry, ref, isLoaded])

  return (
    <MotionBox
      as="video"
      w="100%"
      data-src={src}
      playsInline
      loop
      muted
      variants={props?.variants || variants}
      animate={!props?.variants && (isLoaded ? 'animate' : 'initial')}
      onLoadedData={handleLoadedData}
      {...props}
      ref={ref}
    />
  )
}

const variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: 'linear',
    },
  },
}

export default chakra(Video)
