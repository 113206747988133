import { rem } from 'polished'
import { useEffect, useRef } from 'react'
import { chakra } from '@chakra-ui/react'
import { useSpring } from 'framer-motion'

import { MotionBox, MotionAspectRatio } from 'components/Motion'
import Media, { MediaInterface } from 'components/Media'

import useMouseDistance from 'hooks/useMouseDistance'

interface FeaturedProjectThumbnailProps {
  isActive: boolean
  src: MediaInterface['src']
  alt: MediaInterface['alt']
  contentType: MediaInterface['contentType']
  className?: string
}

const FeaturedProjectThumbnail = ({ className, isActive, src, alt, contentType }: FeaturedProjectThumbnailProps) => {
  const refImage = useRef()

  const distance = useMouseDistance(refImage, isActive)

  const springX = useSpring(0, { stiffness: 400, damping: 200 })
  const springY = useSpring(0, { stiffness: 400, damping: 100 })

  useEffect(() => {
    // TODO: clean up below
    if (distance?.value) {
      const maxPosition = window.innerWidth / 6
      const direction = distance.x > 0 ? 1 : -1
      const factor = (1 - Math.min(Math.exp((distance.value / maxPosition) * -1), 1)) * direction

      springX.set(maxPosition * factor)
      springY.set(distance.y / 2)
    }
  }, [distance, springX, springY])

  return (
    <MotionBox
      className={className}
      pos="absolute"
      right={{ base: rem(-142), xl: rem(-200) }}
      pointerEvents="none"
      style={{
        x: springX,
        y: springY,
      }}
      ref={refImage}
    >
      <MotionAspectRatio
        w={{ base: rem(380), xl: rem(783) }}
        overflow="hidden"
        ratio={380 / 249}
        variants={variantsContainerImage}
        initial="initial"
        animate={isActive ? 'active' : 'initial'}
      >
        <Media
          borderRadius={rem(15)}
          overflow="hidden"
          src={src}
          alt={alt}
          contentType={contentType}
          nextConfig={{
            layout: 'fill',
            objectFit: 'cover',
          }}
          variants={variantsMedia}
        />
      </MotionAspectRatio>
    </MotionBox>
  )
}

const variantsContainerImage = {
  initial: {
    opacity: 0,
    scale: 0.9,
    y: ['0%', '101%'],
    transition: {
      ease: [0.5, 0, 0.25, 1],
      duration: 0.4,
      opacity: {
        delay: 0.3,
        duration: 0.1,
        ease: 'linear',
      },
    },
  },
  active: {
    opacity: 1,
    scale: 1,
    y: ['-101%', '0%'],
    transition: {
      ease: [0.5, 0, 0.25, 1],
      duration: 0.4,
      opacity: {
        duration: 0.1,
        ease: 'linear',
      },
    },
  },
}

const variantsMedia = {
  initial: {
    y: ['0%', '-101%'],
    transition: {
      ease: [0.5, 0, 0.25, 1],
      duration: 0.4,
    },
  },
  active: {
    y: ['101%', '0%'],
    transition: {
      ease: [0.5, 0, 0.25, 1],
      duration: 0.4,
    },
  },
}

export default chakra(FeaturedProjectThumbnail)
